/* global System */
// register component(s) with react habitat

export default {
  StageMain: () =>
    System.import(
      /* webpackChunkName: "StageMain" */
      './data-component'
    ),
}
