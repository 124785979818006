import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_share_icons()
})

function dhsv_vc_share_icons() {
  $('.dhsv_vc_share_icons').each(function () {
    $('.share__link').click(function (e) {
      e.preventDefault()
      var wpWidth = $(window).width(),
        wpHeight = $(window).height()
      window.open(
        $(this).attr('href'),
        'Share',
        'top=' +
          (wpHeight - 400) / 2 +
          ',left=' +
          (wpWidth - 600) / 2 +
          ',width=600,height=400'
      )
    })
  })
}
