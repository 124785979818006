/* Don't forget to add string 'products' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'
import * as _ from 'lodash'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  maxNumPages: 0,
  lastPost: '',
  posts: [],
  filters: {},
  strings: {},
  allEventsDates: {},
  content: '',
  perPage: 3,
  activePage: 1,
  activeFilters: {},
  prefiltered: '',
  hasFilters: false,
  clickedDate: '',
}

const LOAD_EVENTS_DATA = 'LOAD_EVENTS_DATA'
const UPLOAD_EVENTS_DATA = 'UPLOAD_EVENTS_DATA'
const LOAD_EVENTS_DATA_SUCCESS = 'LOAD_EVENTS_DATA_SUCCESS'
const LOAD_EVENTS_DATA_FAILURE = 'LOAD_EVENTS_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_EVENTS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_EVENTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: [...payload.posts],
        activePage: payload.page,
        hasFilters: payload.hasFilters,
        clickedDate: payload.clickedDate,
        activeFilters: payload.activeFilters,
        maxNumPages: parseInt(payload.maxNumPages),
      }

    case LOAD_EVENTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case UPLOAD_EVENTS_DATA:
      return {
        ...state,
        posts: payload.data.posts,
        filters: payload.data.filters,
        lastPost: payload.data.lastPost,
        maxNumPages: parseInt(payload.data.maxNumPages),
        strings: payload.strings,
        content: payload.content,
        allEventsDates: payload.allEventsDates,
        clickedDate: payload.clickedDate,
      }

    default:
      return state
  }
}

export const loadPosts = (page, filters, perPage = 6) => (
  dispatch /*, getState*/
) => {
  dispatch({ type: LOAD_EVENTS_DATA })
  // Rest API Params

  const hasFilters = !_.isEmpty(filters)
  let params = {
    perPage: perPage,
    offset: (page - 1) * perPage,
  }
  if (hasFilters) {
    params = { ...params, ...filters }
  }

  const htmlLang = document.getElementsByTagName('html')[0].getAttribute('lang')

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      Object.prototype.hasOwnProperty.call(params, key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }
  if (htmlLang == 'de-de') {
    url_params = url_params + '&lang=de'
  } else if (htmlLang == 'en-us') {
    url_params = url_params + '&lang=en'
  }

  return fetch(`${WP_API_BASE}/veranstaltung${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        // console.log(response) //eslint-disable-line
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload: {
          posts: payload.posts,
          maxNumPages: payload.maxNumPages,
          page,
          hasFilters,
          activeFilters: filters,
          clickedDate: payload.clickedDate,
        },
        type: LOAD_EVENTS_DATA_SUCCESS,
      })

      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_EVENTS_DATA_FAILURE,
      })
    })
}

export const uploadData = ({
  data,
  content,
  allEventsDates,
  strings,
  prefiltered,
}) => dispatch => {
  dispatch({
    payload: {
      strings,
      allEventsDates,
      data,
      content,
      prefiltered,
    },
    type: UPLOAD_EVENTS_DATA,
  })
}
