import $ from 'jquery'

$(document).ready(load_more_magazin())

function load_more_magazin() {
  $('.js-load-more-magazine').on('click', function () {
    let $container = $(this).closest('.dhsv_vc_magazine_archiv')
    let $items = $container.find('.magazine-item.d-none:lt(6)')
    $items.removeClass('d-none')
    if (!$('.magazine-item.d-none').length) {
      $(this).remove()
    }
  })
}
