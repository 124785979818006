/* global System */
// register component(s) with react habitat

export default {
  MitnahmeorteGrid: () =>
    System.import(
      /* webpackChunkName: "MitnahmeorteGrid" */
      './Component'
    ),
}
