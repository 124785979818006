import cssVars from 'css-vars-ponyfill'

const setBodyWidth = () => {
  cssVars({
    variables: {
      '--body-width': document.body.clientWidth + 'px',
    },
  })
}

const headerScript = () => {
  const header = document.querySelector('#header')
  const stage = document.querySelector('.no-header-offset')

  if (stage == null && header != undefined) {
    document.body.style.paddingTop = header.offsetHeight + 'px'
  }
}

export default {
  init() {
    // JavaScript to be fired on all pages

    setTimeout(() => {
      headerScript()
    }, 500)

    setBodyWidth()

    $(window).on('resize', function () {
      headerScript()
      setBodyWidth()
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
