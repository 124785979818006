import { __ } from '@wordpress/i18n'
import React from 'react'
import PropTypes from 'prop-types'
/* Ref: https://github.com/google-map-react/google-map-react/blob/master/API.md */
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'
import './style.scss'

class GoogleMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isGoogleMapsAccepted:
        typeof window.BorlabsCookie === 'object'
          ? window.BorlabsCookie.checkCookieConsent('googlemaps')
          : false,
    }

    const {
      data: { marker, options, key },
    } = props
    const styles = options.options_styles
      ? require('./Styles/' + options.options_styles)
      : []
    const behavior = options.options_behavior ? options.options_behavior : ''
    const controls = options.options_controls ? options.options_controls : ''

    this.MAP = {
      defaultZoom: options.options_defaultzoom
        ? Number(options.options_defaultzoom)
        : 16,
      defaultCenter: marker
        ? {
            lat: centerItems('lat', marker),
            lng: centerItems('lng', marker),
          }
        : {
            lat: 49.86854,
            lng: 8.92726,
          },
      options: {
        styles: styles,
        maxZoom: Number(options.options_maxzoom),
        minZoom: Number(options.options_minzoom),
        minZoomOverride: options.options_minzoom ? true : false,
        /* MAP BEHAVIOR */
        scrollwheel: behavior.includes('scrollwheel'),
        draggable: behavior.includes('draggable'),
        keyboardShortcuts: behavior.includes('keyboardShortcuts'),
        disableDoubleClickZoom: behavior.includes('disableDoubleClickZoom'),
        /* CONTROLS */
        zoomControl: controls.includes('zoomControl'),
        zoomControlOptions: { position: 5 },
        panControl: controls.includes('panControl'),
        mapTypeControl: controls.includes('mapTypeControl'),
        scaleControl: controls.includes('scaleControl'),
        streetViewControl: controls.includes('streetViewControl'),
        rotateControl: controls.includes('rotateControl'),
        fullscreenControl: controls.includes('fullscreenControl'),
        fullscreenControlOptions: { position: 6 },
      },
      key: key,
    }
  }

  componentDidMount() {
    this.handleConsentMapChange()

    this.intervalId = setInterval(() => {
      this.handleConsentMapChange()
      if (this.state.isGoogleMapsAccepted) {
        clearInterval(this.intervalId)
      }
    }, 500)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  handleConsentMapChange() {
    this.setState({
      isGoogleMapsAccepted:
        typeof window.BorlabsCookie === 'object'
          ? window.BorlabsCookie.checkCookieConsent('googlemaps')
          : false,
    })
  }

  render() {
    const { MAP } = this
    const {
      data: { marker },
    } = this.props
    const { isGoogleMapsAccepted } = this.state

    return (
      <div className="mapwrapper">
        {isGoogleMapsAccepted ? (
          <GoogleMapReact
            ref={e => {
              this.map = e
            }}
            defaultZoom={MAP.defaultZoom}
            defaultCenter={MAP.defaultCenter}
            options={MAP.options}
            onChange={this.handleMapChange}
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: MAP.key }}
          >
            {marker
              ? marker.map((place, key) => (
                  <Marker key={key} lat={place.lat} lng={place.lng} />
                ))
              : null}
          </GoogleMapReact>
        ) : (
          <div className="map-blocker">
            <div className="map-blocker__content">
              <p>
                <b>
                  {__(
                    'Google Maps aufgrund von Datenschutzeinstellungen gesperrt',
                    'dhsv_blocks'
                  )}
                </b>
              </p>
              <p className="small">
                {__(
                  'Die Karte, die Sie versucht haben, von Google Maps zu laden, wurde blockiert, weil Sie das Laden der Karte nicht erlaubt haben.',
                  'dhsv_blocks'
                )}
              </p>
              <button
                className="btn"
                onClick={() => {
                  if (typeof window.BorlabsCookie === 'object') {
                    window.BorlabsCookie.openCookiePreference()
                  }
                }}
              >
                {__('Cookie-Einstellungen aktualisieren', 'dhsv_blocks')}
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

function centerItems(index, array) {
  const sum = array
    .map(item => Number(item[index]))
    .reduce((pre, cur) => (cur += pre))
  return Number(sum / array.length)
}

GoogleMap.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GoogleMap
