import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import veranstaltungenForm from './veranstaltungenForm'
import events from './events'
import events_tab from './events-tab'

const appReducer = combineReducers({
  browser,
  location: router,
  veranstaltungenForm,
  events,
  events_tab,
})

export default appReducer
