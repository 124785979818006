function dhsv_vc_empty_space() {
  const currentBreakpoint = window.bootstrapToolkit.current()
  if (currentBreakpoint !== window.bootstrapToolkit.prev) {
    window.bootstrapToolkit.prev = currentBreakpoint
    $('.vc_empty_space').each(function (idx, emptySpace) {
      const $emptySpace = $(emptySpace)

      let height = $emptySpace.data(currentBreakpoint)
      if (height === undefined) {
        const breakpoints = Object.keys(window.bootstrapToolkit.breakpoints)
        let currentIndex = breakpoints.indexOf(currentBreakpoint)
        for (let i = currentIndex; i < breakpoints.length; i++) {
          if ($emptySpace.data(breakpoints[i]) !== undefined) {
            height = $emptySpace.data(breakpoints[i])
            break
          }
        }
      }
      // height = height || $emptySpace.height()
      $emptySpace.css(
        'height',
        parseInt(height) / parseFloat($('html').css('font-size')) + 'rem'
      )
    })
  }
}

$(document).ready(() => {
  dhsv_vc_empty_space()
})

$(window).resize(dhsv_vc_empty_space)
