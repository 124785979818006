import { devMode } from '../../util/env'
//import { NONCE } from '../../shared/constants'

import compose from 'ramda/es/compose'
import partition from 'ramda/es/partition'
import toPairs from 'ramda/es/toPairs'
import reduce from 'ramda/es/reduce'

const SUBMIT = 'rrm001/tippgeber/SUBMIT'
const SUBMIT_SUCCESS = 'rrm001/tippgeber/SUBMIT_SUCCESS'
const SUBMIT_FAILURE = 'rrm001/tippgeber/SUBMIT_FAILURE'
const LOAD_CHOICES = 'rrm001/tippgeber/LOAD_CHOICES'
const PREFILL_FIELDS = 'rrm001/tippgeber/PREFILL_FIELDS'
const RESET_SUCCESS = 'rrm001/tippgeber/RESET_SUCCESS'

export const initialState = {
  isSubmitting: false,
  error: false,
  success: false,
  initialValues: devMode
    ? {
        //title: 'Veranstaltungstitel*',
        description: '*Beschreibung',
        dates: [{ date: Date.now(), start: 1646132400200, end: 1646154000800 }],
        registration_required: true,
        location_type: 'default',
        //location_name: 'Test Standort',
        //location_street: 'Musterstraße',
        //location_street_number: '1',
        //location_postal_code: '12345',
        //location_city: 'Musterstadt',
        //location_phone: '123456789',
        //website: 'https://www.360vier.de',
        //video_link: 'https://www.youtube.com/watch?v=QH2-TGUlwu4',
        //organizer: 'Test Veranstalter',
        //first_name: 'Max',
        //last_name: 'Mustermann',
        //phone: '+49987654321',
        //email: 'mm@testveranstaltung.de',
      }
    : {
        dates: [{ date: null, start: null, end: null }],
      },
  choices: {},
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SUBMIT:
      return {
        ...state,
        isSubmitting: true,
        error: false,
      }
    case SUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        success: payload.success,
      }
    case SUBMIT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      }
    case LOAD_CHOICES:
      return {
        ...state,
        choices: payload,
      }
    case PREFILL_FIELDS:
      return {
        ...state,
        initialValues: { ...state.initialValues, ...payload },
      }
    case RESET_SUCCESS:
      return {
        ...state,
        success: false,
      }
    default:
      return state
  }
}

const isFileOrOrArrayOfFiles = v =>
  v instanceof File || (v instanceof Array && v[0] && v[0] instanceof File)

const toFormData = compose(
  reduce(
    (formData, [k, v]) => (
      Array.isArray(v)
        ? v.forEach((_v, i) => formData.append(`${k}_${i}`, _v))
        : formData.append(k, v),
      formData
    ),
    new FormData()
  ),
  toPairs
)

const getFormData = compose(
  toFormData,
  ([files, noFiles]) => ({
    ...files,
    fields: JSON.stringify(noFiles),
  }),
  partition(isFileOrOrArrayOfFiles)
)

export const submit = (data, form) => dispatch => {
  // console.log('submitting veranstaltung...') //eslint-disable-line

  if (data.dates && data.dates.length) {
    data.dates.map(item => {
      item.start = item.start.toLocaleTimeString()
      item.end = item.end.toLocaleTimeString()
    })
  }

  dispatch({
    type: SUBMIT,
  })

  return fetch(`/wp-json/dhsv/v1/veranstaltungen/submit`, {
    method: 'POST',
    /* headers: {
      'X-WP-Nonce': NONCE,
    }, */
    body: getFormData(data),
  })
    .then(response => {
      if (![200, 400].includes(response.status)) {
        const message = response.statusText
          ? response.status + ': ' + response.statusText
          : response.status

        throw new Error(message)
      }

      return response.json().then(json => {
        if ('success' in json) {
          dispatch({
            type: SUBMIT_SUCCESS,
            payload: {
              success: json.success,
            },
          })

          form.restart()

          setTimeout(() => {
            dispatch(resetSuccess())
          }, 10000)
        }

        return json
      })
    })
    .catch(error => {
      if (devMode) console.error(error) //eslint-disable-line
      dispatch({
        payload: {
          error: error.message,
        },
        type: SUBMIT_FAILURE,
      })
      return Promise.reject(error)
    })
}

const resetSuccess = () => dispatch => {
  dispatch({
    type: RESET_SUCCESS,
  })
}

export const loadChoices = () => dispatch => {
  return fetch(`/wp-json/dhsv/v1/veranstaltungen/choices`).then(response => {
    if (![200, 400].includes(response.status)) {
      const message = response.statusText
        ? response.statusText
        : response.status

      throw new Error(message)
    }

    return response.json().then(json => {
      dispatch({
        payload: json,
        type: LOAD_CHOICES,
      })

      return json
    })
  })
}

export const prefillFields = () => dispatch => {
  return fetch(`/wp-json/dhsv/v1/veranstaltungen/prefill`, {
    /* headers: {
      'X-WP-Nonce': NONCE,
    }, */
  }).then(response => {
    if (![200, 400].includes(response.status)) {
      const message = response.statusText
        ? response.status + ': ' + response.statusText
        : response.status

      throw new Error(message)
    }

    return response.json().then(json => {
      dispatch({
        type: PREFILL_FIELDS,
        payload: json,
      })

      return json
    })
  })
}
